import React from 'react';
import RentalList from '../components/RentalList'

const RentalPage = () => {
  return (
    <div>
      <h1 className='display-5'>Rental Page</h1>
      <hr></hr>
      <RentalList />
    </div>
  );
};

export default RentalPage;
