import React from 'react';
import AsaActivityList from '../components/AsaActivityList'

const AsaPage = () => {
  return (
    <div>
      <h1>AfterSchool Activities</h1>
      <hr></hr>
      <AsaActivityList />
    </div>
  );
};

export default AsaPage;
